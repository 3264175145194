@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";

.p-breadcrumb {
    background-color: inherit;
    border: none;
    padding-top: 0;
    display: inline-block;
}
.p-menuitem-text {
    font-weight: bold;
    color: inherit;
}

.p-breadcrumb ul li .p-menuitem-link {
    color: inherit;
}

#bigbody {
    position: relative;
    overflow: auto;
    /* width: 99%; */
    // height: calc(100% - 135px);
    //margin: 0;
    // padding-top: 10px;
    // #cntSpinner {
    //     margin-top: -10px;
    //     .p-progress-spinner {
    //         margin-top: -100px;
    //     }
    //   //top: 135px;
    //   //height: calc(100% - 135px);
    // }
}

#bigbody.dialog {
    height: 100%;
    /* padding-top: 0; */
    //#cntSpinner {
    //   top: 0;
    //   height: 100%;
    //   padding-top: 135px;
    //}
}

#tbl_main {
    // height: calc(100% - 56px);
    min-width: 1000px;
    /* width:99%; */
    /* margin-right:4px; */
    margin-top: 0;
    margin-bottom: 0;
}

#tbl_main.dialog {
    height: 100%;
    min-width: inherit;
}

.control-label {
    font-weight: bold;
}

.p-confirmdialog {
    max-height: 80%;
    width: auto;
    display: flex;
    flex-direction: column;
    .p-dialog-content {
        flex: 1;
    }
}

.cntSpinner {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cntSpinnerTransparent {
    background-color: transparent;
}

body .p-datepicker {
    table {
        font-size: 12px;
        td {
            padding: 0;
            line-height: 1;
            // span {
            //     width: 1.5rem;
            //     height: 1.5rem;
            // }
        }
    }
    .p-timepicker {
        padding: 0;
        margin: 0;
        span {
            font-size: 1rem;
        }
        button {
            height: 1rem;
        }
        .p-hour-picker,
        .p-minute-picker {
            padding: 0;
        }
    }
    .p-datepicker-calendar {
        padding: 0;
        margin: 0;
    }
}

@media screen and (max-height: 770px) {
    app-root #tbl_main {
        height: calc(100% - 30px);
    }
}
